import { FC, useCallback, useEffect } from 'react';
import { DataStore } from '~/store';
import {
  AppContainer,
  AppIcon,
  dateRanges,
  EnrichedAccountStatementItems,
  FilterFormikProps,
} from 'common';
import {
  AppTableHeadings,
  AppTableRows,
  Props as ColumnHeaderProps,
  SortProps,
  useAppTableSort,
} from '~/components/app-table';
import cx from 'classnames';
import { AppLoader } from '~/components/app-loader';
import { FilterNavigation } from '~/components/app-table-filters/filters/filter-navigation';
import { pdfDownloadMessage } from '~/utils/pdf-download-message';

enum Column {
  Month = 'Month',
}

const getStatementColumn = (props: SortProps<Column>) => {
  return {
    ...props,
    width: '100%',
    showSort: false,
    name: Column.Month,
    cell: (data: EnrichedAccountStatementItems | null) => {
      /**
       * Store
       */
      const { getStatement } = DataStore.useStoreActions(_ => ({
        getStatement: _.statements.getStatement,
      }));

      /**
       * Methods
       */
      const handleGetPdf = async (
        item: EnrichedAccountStatementItems,
        type: 'view' | 'download'
      ) => {
        const { isSuccessful, result, errorMessage } = await getStatement({
          statementId: item.accountStatementId,
          accountId: item.accountId,
        });
        pdfDownloadMessage(
          isSuccessful,
          result?.url,
          errorMessage,
          type,
          'Could not download PDF statement'
        );
      };

      /**
       * DOM
       */
      if (!data) {
        return null;
      }
      return (
        <div className="flex flex-row justify-between w-full gap-x-5">
          <span className="flex flex-col overflow-hidden">
            <span className="text-sm font-bold truncate">
              {data.accountLabel}
            </span>
            <span className="text-sm truncate text-grey-darker">
              {data.accountNumber}
            </span>
          </span>
          <span className="hidden sm:flex flex-row justify-end gap-x-3">
            <button
              className="app-button-outline button-sm text-sm"
              onClick={() => handleGetPdf(data, 'view')}
            >
              View
            </button>
            <button
              className="app-button-outline button-sm text-sm"
              onClick={() => handleGetPdf(data, 'download')}
            >
              Download
            </button>
          </span>
          <button className="flex sm:hidden items-center">
            <AppIcon icon="download" bg="transparent" cls="top-2 left-5" />
          </button>
        </div>
      );
    },
  };
};

const getColumns = (
  columnProps: SortProps<Column>
): ColumnHeaderProps<Column, EnrichedAccountStatementItems>[] => [
  getStatementColumn(columnProps),
];

export const ListOfStatements: FC = () => {
  /**
   * Store
   */
  const busy = DataStore.useStoreState(s => s.statements.busy);
  const accountNumbers = DataStore.useStoreState(
    s => s.portfolio.accountNumbers
  );
  const list = DataStore.useStoreState(s => s.statements.list);
  const filters = DataStore.useStoreState(s => s.statements.filters);
  const accounts = DataStore.useStoreState(s => s.portfolio.accounts);

  const { getList, setFilters } = DataStore.useStoreActions(_ => ({
    getList: _.statements.getList,
    setFilters: _.statements.setFilters,
  }));

  /**
   * Hooks
   */
  useEffect(() => {
    handleGetListData({
      accountId: filters?.filterInfo,
      endDate: filters?.filterEndDate,
      startDate: filters?.filterStartDate,
    });
  }, []);

  const handleUpdateFilterParams = useCallback(
    (values: FilterFormikProps | null, submit?: boolean) => {
      if (submit) {
        setFilters(values);
        handleGetListData({
          accountId: values?.filterInfo,
          endDate: values?.filterEndDate,
          startDate: values?.filterStartDate,
        });
      }
    },
    []
  );

  const handleGetListData = ({
    startDate = null,
    endDate = null,
    accountId = '',
  }: {
    startDate?: Date | null;
    endDate?: Date | null;
    accountId?: string;
  }) =>
    getList({
      startDate: startDate,
      endDate: endDate,
      assetCode: null,
      accountId: accounts
        .reduce(
          (out, account) => {
            out.push({
              accountNumber: account.account?.accountNumber || null,
              accountId: account.account?.accountId || null,
            });
            return out;
          },
          [] as Record<string, string | null>[]
        )
        .find(({ accountNumber }) => accountNumber === accountId)
        ?.accountId as string,
    });
  /**
   * DOM
   */
  const { columnProps } = useAppTableSort<Column>();
  const columns = getColumns(columnProps);
  return (
    <AppContainer
      containerWidth="xl"
      orderContentCls="order-last md:order-first"
      wrapperCls="h-full p-6"
      cls=""
      rightAddOn={
        <div className="border rounded bg-white">
          <FilterNavigation
            title="Filter"
            filterOnChange
            filters={filters}
            items={accountNumbers}
            filterInfoLabel="Account"
            onClose={handleUpdateFilterParams}
            filterInfoLabelPlaceholder="Choose Account"
            dateFilterOptions={Object.values(dateRanges)?.map(
              ({ text }) => text
            )}
          />
        </div>
      }
    >
      {/* busy  */}
      {busy && <AppLoader isFixed spinnerTop="104px" />}

      <div
        className={cx('flex-1 bg-white', {
          'border rounded-t': !!list?.length,
        })}
      >
        {!list || list.length <= 0 ? (
          <div className="flex flex-row justify-center items-center py-6 bg-white rounded-4 border-1 grey-bright">
            <p className="text-primary font-bold">No statements found</p>
          </div>
        ) : (
          <>
            <AppTableHeadings
              title="Statements"
              columns={[]}
              titleCls="text-xl"
              containerCls="pt-4"
              borderCls="rounded-t-4 border-b-1 border-0 bg-white grey-bright"
            />
            {list?.map(({ periodName, statements }, i) => (
              <div key={periodName} className={cx({ 'border-t': i !== 0 })}>
                <div className="flex text-primary px-8 py-3 grey-bright bg-grey-brightest">
                  <p className="text-primary font-bold text-base">
                    {periodName}
                  </p>
                </div>
                {statements?.map(statement => (
                  <AppTableRows<Column, EnrichedAccountStatementItems>
                    key={statement?.accountStatementId}
                    columns={columns}
                    rows={[statement]}
                    cls="cursor-default hover-bg-grey-brighter"
                    rowPadding="px-8 py-3"
                    containerCls="border-t"
                  />
                ))}
              </div>
            ))}
          </>
        )}
      </div>
    </AppContainer>
  );
};
