import {
  AppContainer,
  AppIcon,
  CurrencyIcon,
  EnrichedCurrencyInformation,
  FILTER_ASSETS_BY,
  PriceCell,
  TextInput,
  useFilterCurrencies,
  getValuePlaceholder,
  ALLOWED_FILTER_ASSETS_BY_LIST,
} from 'common';
import {
  SortProps,
  useAppTableSort,
  Props as ColumnHeaderProps,
  AppTableHeadings,
  AppTableRows,
  SortDirection,
} from '~/components/app-table';
import startCase from 'lodash/startCase';
import cx from 'classnames';
import { DashboardHeader } from '~/components/dashboard/dashboard-header';
import { DataStore } from '~/store';
import { Link } from 'react-router-dom';
import { useCallback, useEffect } from 'react';
import { APP_ROUTES } from '~/routes';
import { handleNavigation } from '~/components/shared/url-params/url-params';
import { MarketsColumn, MarketsHeadings } from '~/config';
import { SearchEmpty } from '~/components/search-empty/search-empty';

const getAssetColumn = (
  props: SortProps<MarketsColumn>,
  heading: string | JSX.Element | undefined
) => {
  return {
    ...props,
    heading,
    width: '25%',
    name: MarketsColumn.Asset,
    cell: (currency: EnrichedCurrencyInformation) => {
      return (
        <span className="text-sm">
          <CurrencyIcon
            size={20}
            currencyCode={currency.code}
            apy={currency.apy}
          />
        </span>
      );
    },
  };
};

const getPriceColumn = (
  props: SortProps<MarketsColumn>,
  heading: string | JSX.Element | undefined
) => {
  return {
    ...props,
    heading,
    width: '20%',
    name: MarketsColumn.Price,
    justify: 'justify-end',
    cell: (currency: EnrichedCurrencyInformation) => {
      return (
        <span className="text-sm font-bold">
          ${currency.fxRate.rateFormatted}
        </span>
      );
    },
  };
};

const getPrice24HourChangeColumn = (
  props: SortProps<MarketsColumn>,
  heading: string | JSX.Element | undefined
) => {
  return {
    ...props,
    heading,
    width: '20%',
    justify: 'justify-end',
    name: MarketsColumn.Price24HourPercentageChange,
    cell: (currency: EnrichedCurrencyInformation) => {
      return currency.fxRate.rate24HoursAgoPercentageChange ? (
        <PriceCell value={currency.fxRate.rate24HoursAgoPercentageChange} />
      ) : (
        getValuePlaceholder()
      );
    },
  };
};

const getPrice7DaysChangeColumn = (
  props: SortProps<MarketsColumn>,
  heading: string | JSX.Element | undefined
) => {
  return {
    ...props,
    heading,
    width: '20%',
    justify: 'justify-end',
    name: MarketsColumn.Price7DaysPercentageChange,
    cell: (currency: EnrichedCurrencyInformation) => {
      return currency.fxRate.rate7DaysAgoPercentageChange ? (
        <PriceCell value={currency.fxRate.rate7DaysAgoPercentageChange} />
      ) : (
        getValuePlaceholder()
      );
    },
  };
};

const getPrice1MonthChangeColumn = (
  props: SortProps<MarketsColumn>,
  heading: string | JSX.Element | undefined
) => {
  return {
    ...props,
    heading,
    width: '20%',
    justify: 'justify-end',
    name: MarketsColumn.Price1MonthPercentageChange,
    cell: (currency: EnrichedCurrencyInformation) => {
      return currency.fxRate.rate1MonthAgoPercentageChange ? (
        <PriceCell value={currency.fxRate.rate1MonthAgoPercentageChange} />
      ) : (
        getValuePlaceholder()
      );
    },
  };
};

const getColumns = (
  columnProps: SortProps<MarketsColumn>
): ColumnHeaderProps<MarketsColumn, EnrichedCurrencyInformation>[] => {
  return [
    getAssetColumn(columnProps, MarketsHeadings[MarketsColumn.Asset]),
    getPriceColumn(columnProps, MarketsHeadings[MarketsColumn.Price]),
    getPrice24HourChangeColumn(
      columnProps,
      MarketsHeadings[MarketsColumn.Price24HourPercentageChange]
    ),
    getPrice7DaysChangeColumn(
      columnProps,
      MarketsHeadings[MarketsColumn.Price7DaysPercentageChange]
    ),
    getPrice1MonthChangeColumn(
      columnProps,
      MarketsHeadings[MarketsColumn.Price1MonthPercentageChange]
    ),
  ];
};

enum SORT_PARAMS {
  COLUMN = 'column',
  FILTER_BY = 'marketsFilterBy',
  DIRECTION = 'direction',
}

export const ListOfAssets = () => {
  /**
   * Hooks
   */
  const {
    applyParams,
    queryParams: { column, marketsFilterBy, direction },
  } = handleNavigation<SORT_PARAMS>({
    [SORT_PARAMS.FILTER_BY]: FILTER_ASSETS_BY.ALL,
    [SORT_PARAMS.COLUMN]: MarketsColumn.Asset,
    [SORT_PARAMS.DIRECTION]: SortDirection.ASC,
  });
  const { currencies, filterBy, setFilterBy, setSearch, search } =
    useFilterCurrencies(DataStore, [], { filterForMarkets: true });
  const { columnProps, sortInfo, sortAndFilterMarketsData } =
    useAppTableSort<MarketsColumn>({
      column: (column || MarketsColumn.Asset) as MarketsColumn,
      direction: (direction || SortDirection.ASC) as SortDirection,
    });

  /**
   * Methods
   */
  const handleOnchange = useCallback(
    value => {
      setSearch(value);
    },
    [setSearch]
  );

  /**
   * Hooks
   */
  useEffect(() => {
    const filterOption =
      FILTER_ASSETS_BY[`${marketsFilterBy}`.toLocaleUpperCase()];
    if (filterOption) {
      setFilterBy(filterOption);
      return;
    }
  }, []);

  useEffect(() => {
    // Note: @design team wants this page to not load with scrolled offset
    window.scrollTo(0, 0);
    // clean up
    return () => {};
  }, []);

  /**
   * Methods
   */
  const handleOnSortChange = useCallback(
    arg => {
      applyParams(
        {
          [SORT_PARAMS.FILTER_BY]: filterBy,
          [SORT_PARAMS.COLUMN]: arg?.column,
          [SORT_PARAMS.DIRECTION]: arg?.direction,
        },
        { append: true }
      );
    },
    [filterBy, applyParams]
  );

  /**
   * DOM
   */
  const columns = getColumns(columnProps);
  const rows = sortAndFilterMarketsData(
    currencies as EnrichedCurrencyInformation[],
    sortInfo
  );
  return (
    <AppContainer fullHeight containerWidth="md">
      <div className="bg-white">
        <DashboardHeader withBorder title="Markets">
          <div className="px-4 md:px-8">
            <div className="flex relative items-center sm:max-w-sm">
              <TextInput
                type="text"
                id="markets-search"
                value={search}
                spellCheck={false}
                leftAddonBorder={false}
                handleClearInput={() => handleOnchange('')}
                leftAddon={
                  <AppIcon icon="search-input" bg="bg-transparent" size={18} />
                }
                onChange={({ target }) => handleOnchange(target?.value)}
              />
            </div>
            <div className="flex whitespace-nowrap overflow-auto outline scrollbar-hide pt-8 pb-5 gap-4 ">
              {ALLOWED_FILTER_ASSETS_BY_LIST.map(item => (
                <button
                  key={item}
                  className={cx(
                    {
                      'app-button-outline': marketsFilterBy !== item,
                      'app-button-primary': marketsFilterBy === item,
                    },
                    'button-sm text-xs snap-center outline-none focus:outline-none'
                  )}
                  onClick={() => {
                    applyParams(
                      {
                        [SORT_PARAMS.FILTER_BY]: item,
                      },
                      {
                        append: true,
                        replace: true,
                        callBack: () => setFilterBy(item),
                      }
                    );
                  }}
                >
                  {startCase(item)}
                </button>
              ))}
            </div>
          </div>
        </DashboardHeader>
        <div className="snap-x snap-mandatory min-w-7xl max-w-7xl overflow-x-auto">
          <div className="min-w-680">
            <AppTableHeadings<MarketsColumn, EnrichedCurrencyInformation>
              borderCls="border-1 bg-white grey-bright"
              columns={columns}
              borderColor="grey-bright"
              handleSort={handleOnSortChange}
            />
            <AppTableRows<MarketsColumn, EnrichedCurrencyInformation>
              as={Link}
              to={row => `${APP_ROUTES.AUTH_ASSET}/${row.code}`}
              columns={columns}
              rows={rows as EnrichedCurrencyInformation[]}
              rowPadding="px-4 md:px-8 py-5"
              cellRowCls="gap-x-2"
              cellItemCls="truncate"
              borderBottomColor="grey-bright"
              emptyDataText={<SearchEmpty title="No matching assets found" />}
              containerCls="rounded-b-4 border-t-0 border-1 grey-bright"
            />
          </div>
        </div>
      </div>
    </AppContainer>
  );
};
