import { AppContainer, Enriched } from 'common';
import { APP_ROUTES } from '~/routes';
import { DataStore } from '~/store';
import { AdminFundManageFund } from './manage';
import { Widgets } from '~/components/widgets';
import { AdminFundAddresses } from './addresses';
import { AppLoader } from '~/components/app-loader';
import { AdminFundBankDetails } from './bank-details';
import { PortfolioHero } from '~/components/portfolio';
import { AppTabs } from '~/components/app-tabs/app-tabs';
import { useNavigate, useParams } from 'react-router-dom';
import { FC, useCallback, useEffect, useState } from 'react';
import { SortDataProps, initSortInfo } from '~/pages/portfolio/account-detail';
import { PortfolioAccountsList } from '~/components/portfolio/accounts/list';
import { useAppTabs } from '~/components/app-tabs/use-app-tabs';

export enum PORTFOLIO_TAB {
  ACCOUNTS = 'Accounts',
  HOLDINGS = 'Holdings',
  BANK_DETAILS = 'Bank Details',
  ADDRESSES = 'Addresses',
  MANAGE_FUND = 'Manage',
}

export const AdminFundAccounts: FC = () => {
  const { fundId } = useParams();
  /**
   * Store
   */

  const busy = DataStore.useStoreState(s => s.busy);
  const selectedFund = DataStore.useStoreState(s => s.admin.fundAccountByName);
  const decodedTokenAccountId = DataStore.useStoreState(
    s => s.user.decodedTokenAccountId
  );
  const totalBalanceUsd = DataStore.useStoreState(
    s => s.portfolio.totalBalanceUsd
  );
  const accounts = DataStore.useStoreState(s => s.portfolio.accounts);
  const showRelationshipManagerInfo = DataStore.useStoreState(
    s => s.settings.globalAppSettings?.showRelationshipManagerInfo
  );
  const tradingDeskContactPhoneLink = DataStore.useStoreState(
    s => s.settings.globalAppSettings?.tradingDeskContactPhoneLink
  );
  const hasAccountOfTypeCustody = DataStore.useStoreState(
    s => s.portfolio.hasAccountOfTypeCustody
  );
  const hasAccountOfTypeTrading = DataStore.useStoreState(
    s => s.portfolio.hasAccountOfTypeTrading
  );
  const relationshipManagerEmailLink = DataStore.useStoreState(
    s => s.settings.globalAppSettings?.relationshipManagerEmailLink
  );
  const hasAccountOfTypeProTrading = DataStore.useStoreState(
    s => s.portfolio.hasAccountOfTypeProTrading
  );

  const { portfolio, setSelectedFundAccountId } = DataStore.useStoreActions(
    _ => ({
      portfolio: _.portfolio,
      setSelectedFundAccountId: _.admin.setSelectedFundAccountId,
    })
  );
  const tradingDeskContactName = DataStore.useStoreState(
    s => s.settings.globalAppSettings?.tradingDeskContactName
  );
  const tradingDeskContactPhone = DataStore.useStoreState(
    s => s.settings.globalAppSettings?.tradingDeskContactPhone
  );
  const relationshipManagerName = DataStore.useStoreState(
    s => s.settings.globalAppSettings?.relationshipManagerName
  );
  const relationshipManagerEmail = DataStore.useStoreState(
    s => s.settings.globalAppSettings?.relationshipManagerEmail
  );

  /**
   * State
   */
  const [sortInfo, setSortInfo] = useState<Pick<SortDataProps, 'TabIndex'>>({
    TabIndex: initSortInfo.TabIndex,
  });

  /**
   * Hooks
   */
  const { tabIndex, handleOnTabClicked } = useAppTabs(PORTFOLIO_TAB);
  const navigate = useNavigate();
  useEffect(() => {
    if (!fundId) {
      navigate(APP_ROUTES.AUTH_PORTFOLIO);
      return;
    }
    setSelectedFundAccountId(fundId);
    portfolio.getPortfolio({
      accountId: decodedTokenAccountId,
      impersonatedAccountId: fundId,
    });
  }, [fundId, decodedTokenAccountId]);

  /**
   * Methods
   */
  const toHandler = useCallback(
    (accountItem: Enriched.ListAccountItem | null) =>
      APP_ROUTES.AUTH_ADMIN_FUND_ACCOUNT_OVERVIEW +
      `/${fundId}/accounts/${accountItem?.account?.accountId}`,
    []
  );

  /**
   * DOM
   */

  return (
    <AppContainer cls="py-10 px-4 xl:px-0" containerWidth="lg">
      {/* balance hero  */}
      <PortfolioHero
        balance={totalBalanceUsd}
        title={
          <div className="flex flex-col gap-0.25">
            <div className="text-2xl font-semibold text-primary">
              {selectedFund?.accountName}
            </div>
            <p className="text-sm font-medium text-gray-400">
              Fund manager: {selectedFund?.fundManagerName}
            </p>
          </div>
        }
      />
      <AppTabs
        marginCls="mt-6"
        headerContainerCls="mt-6"
        headerItemCls="border-primary"
        borderCls=""
        tabs={[
          <div
            key={PORTFOLIO_TAB.ACCOUNTS}
            className="capitalize text-base pb-1.5 flex justify-center"
          >
            {PORTFOLIO_TAB.ACCOUNTS}
          </div>,
          <div
            key={PORTFOLIO_TAB.BANK_DETAILS}
            className="capitalize text-base pb-1.5 flex justify-center"
          >
            {PORTFOLIO_TAB.BANK_DETAILS}
          </div>,
          <div
            key={PORTFOLIO_TAB.ADDRESSES}
            className="capitalize text-base pb-1.5 flex justify-center"
          >
            {PORTFOLIO_TAB.ADDRESSES}
          </div>,

          <div
            key={PORTFOLIO_TAB.MANAGE_FUND}
            className="capitalize text-base pb-1.5 flex justify-center"
          >
            {PORTFOLIO_TAB.MANAGE_FUND}
          </div>,
        ]}
        panels={[
          busy ? (
            <AppLoader isFixed spinnerTop="104px" />
          ) : (
            <div key={PORTFOLIO_TAB.ACCOUNTS}>
              {/* list of accounts  */}
              <AppContainer
                cls=""
                containerWidth="lg"
                {...(showRelationshipManagerInfo
                  ? {
                      rightAddOn: (
                        <div className="flex flex-col gap-6">
                          {/**trading desk */}
                          <>
                            <Widgets.DashboardContactCard
                              title={`Trade via Trading desk`}
                              relationshipManagerNameTitle="Name"
                              relationshipManagerName={tradingDeskContactName}
                              relationshipManagerContactNumberTitle={`Recorded line`}
                              relationshipManagerContactNumber={
                                <a href={tradingDeskContactPhoneLink}>
                                  {tradingDeskContactPhone}
                                </a>
                              }
                            />
                            {/* contact widget*/}
                            <Widgets.DashboardContactCard
                              title={`Your relationship manager`}
                              relationshipManagerNameTitle="Name"
                              relationshipManagerName={relationshipManagerName}
                              relationshipManagerEmailTitle="Email"
                              relationshipManagerEmail={
                                <a href={relationshipManagerEmailLink}>
                                  {relationshipManagerEmail}
                                </a>
                              }
                            />
                          </>
                        </div>
                      ),
                    }
                  : null)}
              >
                <PortfolioAccountsList accounts={accounts} to={toHandler} />
                <div className="flex flex-col gap-y-6">
                  <Widgets.WidgetPortfolioHome
                    title="Discover more"
                    showAccountTypeInfo
                    hasAccountOfTypeCustody={hasAccountOfTypeCustody}
                    hasAccountOfTypeTrading={hasAccountOfTypeTrading}
                    hasAccountOfTypeProTrading={hasAccountOfTypeProTrading}
                    hasRelationshipManager={showRelationshipManagerInfo}
                  />
                  <Widgets.WidgetPortfolioHome title="Help" showHelpInfo />
                </div>
              </AppContainer>
            </div>
          ),
          <div key={PORTFOLIO_TAB.BANK_DETAILS}>
            <AdminFundBankDetails />
          </div>,
          <div key={PORTFOLIO_TAB.ADDRESSES}>
            <AdminFundAddresses />
          </div>,
          <div key={PORTFOLIO_TAB.MANAGE_FUND}>
            <AdminFundManageFund />
          </div>,
        ]}
        selectedIndex={tabIndex}
        onChange={handleOnTabClicked}
      />
    </AppContainer>
  );
};
