import { API } from 'api';
import { action, Action, Computed, computed, thunk } from 'easy-peasy';
import { runApi, FilterFormikProps } from '../../utils';
import { ApiThunk } from '../types';
import {
  EnrichedAccountStatement,
  EnrichedAccountStatementItems,
} from '../../types/enriched';
import { DataModel } from '../data-store';
import { BaseModel, createBaseModel } from '../base-store';

const computePdfName = (
  item: API.AccountStatementItems,
  period: string | null
) => {
  const fallbackName = (
    item.fileName ||
    item.accountLabel ||
    'statement'
  ).replace('.pdf', '');
  const suffix = period ? period.replace(' ', '-') : Date.now();
  return `${fallbackName}-${suffix}`;
};

export interface StatementsModel extends BaseModel {
  // statements
  _list: API.AccountStatement[] | null;
  setList: Action<StatementsModel, API.AccountStatement[] | null>;
  getList: ApiThunk<
    StatementsModel,
    API.ListAccountStatementRequest,
    API.AccountStatement[]
  >;
  list: Computed<StatementsModel, EnrichedAccountStatement[] | null, DataModel>;

  // statement
  getStatement: ApiThunk<
    StatementsModel,
    {
      statementId: string;
      accountId: string;
    },
    API.GetStatementPdfResponse
  >;

  // filters
  filters: FilterFormikProps | null;
  setFilters: Action<StatementsModel, FilterFormikProps | null>;
}

export const statementsModel: StatementsModel = {
  ...createBaseModel(),

  // statements
  _list: null,
  setList: action((state, payload) => {
    state._list = payload;
  }),
  getList: thunk((actions, payload, helpers) => {
    return runApi(
      actions,
      helpers,
      () => {
        return helpers.injections.apiClient.accountStatementList(
          Object.assign(
            {
              startDate: null,
              endDate: null,
              accountId: helpers.getStoreState().user.decodedTokenAccountId,
            },
            payload
          )
        );
      },
      result => {
        actions.setList(result);
      },
      {
        'x-account-id':
          payload.accountId ||
          helpers.getStoreState().user.decodedTokenAccountId,
      }
    );
  }),
  list: computed([s => s._list], _list => {
    if (!_list || !_list.length) {
      return null;
    }

    const accumulator: EnrichedAccountStatement[] = [];

    const enriched = _list.reduce((out, item) => {
      let statements: EnrichedAccountStatementItems[] | null = null;
      if (item.statements && item.statements.length > 0) {
        statements = item.statements.map(st => {
          const labelExtra =
            (st.accountType === 'Fund' &&
              st.currency?.name &&
              ` / ${st.currency.name}`) ||
            '';
          return {
            ...st,
            accountLabel: st.accountLabel + labelExtra,
            downloadFileName: computePdfName(st, item.periodName),
          };
        });
      }
      const value: EnrichedAccountStatement = {
        periodName: item.periodName,
        periodType: item.periodType,
        statements,
      };
      out.push(value);
      return out;
    }, accumulator);

    return enriched;
  }),

  // statement
  getStatement: thunk((actions, payload, helpers) => {
    return runApi(
      actions,
      helpers,
      () => {
        return helpers.injections.apiClient.getStatementPdf(
          payload.statementId
        );
      },
      result => result,
      {
        'x-account-id':
          payload.accountId ||
          helpers.getStoreState().user.decodedTokenAccountId,
      }
    );
  }),

  // filters
  filters: null,
  setFilters: action((state, payload) => {
    state.filters = payload;
  }),
};
