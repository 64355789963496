import cx from 'classnames';
import {
  FundShowDepositDetails,
  ReceiveFormikProps,
  ReceiveMethod,
  validateReceiveFormik,
  ReceiveFormikField,
  ValidationMessage,
  FormHeader,
} from 'common';
import { Formik } from 'formik';
import { FC, useEffect } from 'react';
import { StepWizardChildProps } from 'react-step-wizard';
import { AppStore, DataStore } from '../../../store';
import { SelectField } from '../../forms/select-field';
import { Preview } from './preview';
import { ReceiveProps } from '.';
import { NotAllowed } from '../shared/not-allowed';
import { useValidateOnLoad } from '~/components/forms/use-validate-on-load';
import { getAccountInfo } from '~/utils/get-account-details';
import { TextField } from '~/components/forms/text-field';
import { ImitationSelectField } from '~/components/lib';
import { RECEIVE_STEPS } from '~/components/workflows/receive/steps';
import { singleCurrencySelectedItemTemplate } from '~/components/app-selector/templates';

type Props = {
  accountId: string | undefined;
  impersonatedAccountId: string | undefined;
};
export const Form: FC<
  Partial<StepWizardChildProps & FundShowDepositDetails> & ReceiveProps & Props
> = ({
  goToNamedStep,
  fundDetailsType,
  onClose,
  accountId,
  impersonatedAccountId,
}) => {
  /**
   * Store
   */
  const accountDetail = DataStore.useStoreState(s => s.portfolio.accountDetail);
  const formValues = DataStore.useStoreState(s => s.receive.formValues);
  const details = DataStore.useStoreState(s => s.receive.details);
  const confirmed = DataStore.useStoreState(s => s.receive.confirmed);
  const error = DataStore.useStoreState(s => s.receive.error);
  const resetState = DataStore.useStoreActions(a => a.receive.resetState);
  const getDetails = DataStore.useStoreActions(a => a.receive.getDetails);
  const getDepositableCurrencies = DataStore.useStoreActions(
    a => a.receive.getDepositableCurrencies
  );
  const setFormValues = DataStore.useStoreActions(a => a.receive.setFormValues);
  const dashboardSelectedAsset = AppStore.useStoreState(
    s => s.dashboardSelectedAsset
  );
  const setDashboardSelectedDialogType = AppStore.useStoreActions(
    a => a.setDashboardSelectedDialogType
  );
  const setDashboardSelectedAsset = AppStore.useStoreActions(
    a => a.setDashboardSelectedAsset
  );

  useEffect(() => {
    (async () => {
      // TODO(Hadrien): manage impersonatedAccountId ? (in search too ?)
      await getDepositableCurrencies({ accountId });
    })();
  }, []);

  /**
   * Methods
   */
  const onFormValidate = async (values: ReceiveFormikProps) => {
    const errors = validateReceiveFormik(values);
    const hasErrors = Object.keys(errors).length > 0;
    const currencyCode = values.toAsset?.code;
    if (!hasErrors) {
      setFormValues(values);
      if (
        confirmed &&
        accountId &&
        currencyCode &&
        currencyCode != details?.currencyCode
      ) {
        await getDetails({
          currencyCode,
          accountId,
          impersonatedAccountId,
        });
      }
    }
    return errors;
  };

  const onFormSubmit = async () => {
    if (error) {
      return;
    }

    goToNamedStep?.(RECEIVE_STEPS.ATTENTION);
  };

  /**
   * DOM
   */
  if (
    dashboardSelectedAsset &&
    !!dashboardSelectedAsset.currency.isAssetOfTypeFiat
  ) {
    return (
      <NotAllowed
        message={`Receive operation is not allowed for ${dashboardSelectedAsset.currency.displayCode} (${dashboardSelectedAsset.currency.name})`}
        onClose={() => setDashboardSelectedDialogType(null)}
      />
    );
  }
  const initialValues: ReceiveFormikProps = formValues || {
    receiveMethod: ReceiveMethod.crypto,
    toAsset: dashboardSelectedAsset?.currency || null,
    network: dashboardSelectedAsset?.currency.network || null,
  };
  return (
    <div
      data-testid="receive-form"
      className="flex flex-col text-primary relative"
    >
      {/* header  */}
      {!confirmed && (
        <FormHeader
          title={
            fundDetailsType
              ? `Select currency`
              : `Receive ${formValues?.toAsset?.displayCode || ''}`
          }
          accountInfo={getAccountInfo(accountDetail?.account, 'To')}
          onClose={onClose}
        />
      )}

      {/* form  */}
      <Formik<ReceiveFormikProps>
        initialValues={initialValues}
        validate={onFormValidate}
        validateOnBlur={false}
        validateOnMount={false}
        validateOnChange
        onSubmit={onFormSubmit}
      >
        {({
          values,
          errors,
          isValidating,
          submitForm,
          setFieldValue,
          initialValues: onLoadValues,
          validateForm,
        }) => {
          /**
           * Hooks
           */
          useValidateOnLoad(onLoadValues, validateForm);

          /**
           * Methods
           */
          const onMethodChanged = async (newValue: ReceiveMethod) => {
            if (isValidating) {
              return;
            }
            await setFieldValue(
              ReceiveFormikField.receiveMethod,
              newValue,
              true
            );
          };
          const onCancel = (e: React.MouseEvent<HTMLButtonElement>) => {
            e.preventDefault();
            setDashboardSelectedAsset(null);
            setDashboardSelectedDialogType(null);
            resetState();
            if (fundDetailsType && onClose) onClose();
          };
          const onNext = async (e: React.MouseEvent<HTMLButtonElement>) => {
            e.preventDefault();
            submitForm();
          };

          /**
           * Form DOM
           */
          const hasErrors = Object.keys(errors).length > 0;
          const methods = [ReceiveMethod.crypto];
          if (accountDetail?.canStabletagTransfer) {
            methods.push(ReceiveMethod.transfer);
          }
          const chooseCurrency = (
            <div className="flex flex-col mb-2 sm:mb-0 sm:flex-1">
              <ImitationSelectField
                label="Choose the currency you want to receive"
                onClick={() => goToNamedStep?.(RECEIVE_STEPS.CHOOSE_ASSET)}
              >
                {singleCurrencySelectedItemTemplate(
                  values?.toAsset || undefined
                )}
              </ImitationSelectField>
            </div>
          );
          const chooseMethod = (
            <div className="flex flex-col mb-2 sm:mb-0 sm:flex-1">
              <SelectField
                name={ReceiveFormikField.receiveMethod}
                label="Choose receive method"
                disableHelpers
                onChange={onMethodChanged}
                value={values.receiveMethod}
                values={methods}
                disabled={methods?.length < 2}
              />
            </div>
          );
          const chooseNetwork = (
            <TextField disabled name="network" label="Network" />
          );
          if (confirmed) {
            return (
              <Preview
                goToNamedStep={goToNamedStep}
                fundDetailsType={fundDetailsType}
                chooseCurrency={chooseCurrency}
                chooseMethod={chooseMethod}
                chooseNetwork={chooseNetwork}
              />
            );
          }
          const isDisabled =
            isValidating || error !== null || hasErrors || !formValues;
          return (
            <>
              {/* fields  */}
              <div className="px-6 md:px-10">
                {/* select crypto  */}
                <div
                  className={cx('flex flex-col', {
                    // hidden: values.receiveMethod !== ReceiveMethod.crypto,
                  })}
                >
                  {/* crypto dropdown  */}
                  <div className={cx('flex flex-col sm:flex-row mt-3 gap-x-5')}>
                    {chooseCurrency}
                  </div>

                  {/* deposit method  */}
                  <div className="flex flex-col sm:flex-row">
                    {chooseMethod}
                  </div>

                  {/* network dropdown  */}
                  <div
                    className={cx('flex flex-col mb-2 sm:mb-0 sm:flex-1 mt-3', {
                      hidden: !values.toAsset || !values.network,
                    })}
                  >
                    {chooseNetwork}
                  </div>
                </div>
              </div>

              <ValidationMessage
                message={error}
                cls="mx-10 mb-4"
                type="global"
              />

              {/* actions  */}
              <div className="flex flex-col sm:flex-row gap-5 border-t border-grey-bright bg-gray-100 rounded-b py-6 px-6 md:px-10 mt-12">
                {/* cancel button  */}
                <button
                  className="app-button-outline w-full sm:mr-3 flex-1"
                  onClick={onCancel}
                >
                  Cancel
                </button>
                {/* withdraw button  */}
                <button
                  disabled={isDisabled}
                  type="button"
                  className="app-button-primary flex-1 flex items-center justify-center"
                  onClick={onNext}
                >
                  Next
                </button>
              </div>
            </>
          );
        }}
      </Formik>
    </div>
  );
};
