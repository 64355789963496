import { API } from 'api';

import {
  EnrichedCurrencyInformation,
  EnrichedInternalTransfer,
} from '../../types';
import { formatMoney } from '../format-money';
import { formatters } from '../formatters';
import { FIAT_SYMBOLS } from '../../constants';

export function createEnrichedInternalTransfer(
  transfer: API.InternalTransfer | API.InternalTransferWithTimeline,
  currencies: EnrichedCurrencyInformation[],
  fiatCurrencyCodes: string[]
): EnrichedInternalTransfer {
  const amount = transfer.amount ?? '0';
  const amountUsd = transfer.amountUsd ?? '0';
  const sendingAmount = transfer.sendingAmount ?? '0';
  const sendingAmountUsd = transfer.sendingAmountUsd ?? '0';
  const fee = transfer.fee ?? '0';
  const feeUsd = transfer.feeUsd ?? '0';
  const totalAmountUsd = transfer.totalAmountUsd ?? '0';

  const currencyCode = transfer.currencyCode ?? '';
  const currency = currencyCode
    ? currencies.find(_currency => _currency.code === currencyCode)
    : undefined;

  const displayCurrencyCode = currency?.displayCode ?? currency?.code ?? '';

  const formattedAmount = formatters.getCurrency(
    amount,
    currency?.decimals,
    displayCurrencyCode,
    fiatCurrencyCodes
  );

  const formattedSendingAmount = `${sendingAmount} ${displayCurrencyCode}`;
  // formatters.getCurrency(
  //   sendingAmount,
  //   currency?.decimals,
  //   displayCurrencyCode,
  //   fiatCurrencyCodes
  // );

  return {
    ...transfer,
    amount: Number(amount),
    amountUsd: Number(amountUsd),
    currencyCode,
    fee: Number(fee),
    feeUsd: Number(feeUsd),
    totalAmountUsd: Number(totalAmountUsd),
    formattedAmount,
    formattedAmountAndSign: formatters.forceSign(formattedAmount, '+'),
    formattedAmountUsd: formatMoney(amountUsd, 'USD'),
    formattedSendingAmount,
    formattedSendingAmountAndSign: formatters.forceSign(
      formattedSendingAmount,
      '-'
    ),
    formattedSendingAmountUsd: formatMoney(sendingAmountUsd, 'USD'),
    formattedFee: FIAT_SYMBOLS[displayCurrencyCode]
      ? formatMoney(fee, displayCurrencyCode, currency?.decimals)
      : `${fee} ${displayCurrencyCode}`,
    formattedFeeUsd: formatMoney(feeUsd, 'USD'),
    formattedTotalAmountUsd: formatMoney(totalAmountUsd, 'USD'),
    timeline: (transfer as API.InternalTransferWithTimeline).timeline ?? null,
  };
}
